<template>
  <div class="sound-effect">
    <!-- 标签 -->
    <div class="tag-container">
      <div class="tag-wrap container_media">
        <div class="tag-item">
          <div class="tag-item-title">场景：</div>
          <div class="tag-item-content">
            <span class="tag-item-content-text" :class="{ 'active': !labelIds }" @click="setActiv(null, null)">全部</span>
            <span class="tag-item-content-text" :class="{ 'active': o.label_id == labelIds }" v-for="(o, i) in labelList"
              :key="i" @click="setActiv(o, i)">{{ o.label_name }}</span>
          </div>
        </div>
        <div class="tag-item" v-if="labelIds">
          <div class="tag-item-title">类型：</div>
          <div class="tag-item-content">
            <span class="tag-item-content-text" :class="{ 'active': !labelChilIds }"
              @click="setActivChild(null)">全部</span>
            <span class="tag-item-content-text" :class="{ 'active': o.label_id == labelChilIds }"
              v-for="(o, i) in labelChild" :key="i" @click="setActivChild(o)">{{ o.name }}</span>
          </div>
        </div>
        <div class="tag-sort-box">
          <div class="tag_sort">
            <div class="tag_sort_title">排序方式：</div>
            <div class="tag_sort_content">
              <span class="tag_sort_text" :class="{ 'active': item.value == orderNo }" v-for="(item, index) in orderList"
                :key="index" @click="setOrder(item)">{{ item.label }}</span>
            </div>
          </div>
          <div class="tag-pagina">
            <i class="pagina_l el-icon-arrow-left" :class="{ 'active': lpage }" @click="prevPage"></i>
            <span class="pager">
              <i class="page">{{ pager.page }}</i>
              <i class="pagesize">/{{ pages }}</i>
            </span>
            <i class="pagina_r el-icon-arrow-right" :class="{ 'active': rpage }" @click="nextPage"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="order_cont" v-if="false">
      <label for="">排序：</label>
      <el-select v-model="orderNo" placeholder="请选择" :popper-append-to-body="false" @change="changeSoundOrder">
        <el-option v-for="item in orderList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <!-- 音效列表 -->
    <div class="sound-effect-list container_media">
      <div class="crumb-wapper">
        <vf-breadcrumb :data="breadData"></vf-breadcrumb>
      </div>
      <effectCard :orderNo="typeof (orderNo) == 'string' ? 1 : orderNo" :list="list" :pageQuery="{}"></effectCard>
      <!-- <div class="sound-effect-list-content" v-if="list.length > 0">
        <SoundEffectCard
          v-for="(item, index) in list"
          :key="index"
          :data="item"
          :orderNo="typeof(orderNo) == 'string' ? 1 : orderNo "
        />
      </div> -->
      <Pagination style="margin-top: 50px;" :hiddenPage="true" :total="pager.total" :limit.sync="pager.limit"
        :page.sync="pager.page" :hidden-page="false" @pagination="handlePageChange" />
    </div>
  </div>
</template>

<script>
import { fetchSoundEffect, getSoundLabel } from '@/api/soundEffect'
// import Tag from '@/components/Tag'
// import SoundEffectCard from '@/components/SoundEffectCard'
import Pagination from '@/components/Pagination'
import effectCard from '@/components/member/effectCard';
import { orderList } from '@/config'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  // SoundEffectCard
  components: { effectCard, Pagination, VfBreadcrumb },
  // components: { Tag, SoundEffectCard, Pagination },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '音效'}
      ],
      list: [],
      labelList: [],
      labelIds: "",
      labelChilIds: "",
      labelIdx: null,
      orderList,
      pager: {
        total: 0,
        page: 1,
        limit: 32
      },
      orderNo: '',
      lpage: false,
      rpage: false
    }
  },
  created() {
    this.getLabel();
    this.getList();
  },
  computed: {
    pages() {
      return Math.ceil(this.pager.total / this.pager.limit);
    },
    labelChild() {
      if (!this.labelIds) {
        return (this.labelList && this.labelList[0] && this.labelList[0].son) || [];
      } else {
        return (this.labelList && this.labelList[this.labelIdx] && this.labelList[this.labelIdx].son) || [];
      }
    }
  },
  methods: {
    // 音效标签
    async getLabel() {
      const { data } = await getSoundLabel();
      this.labelList = data || [];
    },
    setActiv(item, index) {
      if (item) {
        this.labelIds = item.label_id;
        this.labelIdx = index;
      } else {
        this.labelIds = "";
        this.labelIdx = null;
      }
      this.labelChilIds = "";
      this.pager.page = 1;
      this.getList();
    },
    setActivChild(item) {
      if (item) {
        this.labelChilIds = item.label_id;
      } else {
        this.labelChilIds = "";
      }
      this.pager.page = 1;
      this.getList();
    },
    setOrder(item) {
      this.orderNo = item.value;
      this.pager.page = 1;
      this.getList();
    },
    prevPage() {
      if (this.pager.page < 2) return;
      this.pager.page--;
      this.getList();
      this.lpage = true;
      this.rpage = false;
    },
    nextPage() {
      if (this.pager.page < this.pages) {
        this.pager.page++;
        this.getList();
        this.lpage = false;
        this.rpage = true;
      }
    },

    /**
     * 获取列表
     */
    async getList() {
      try {
        let label_id = "";
        if (this.labelIds && !this.labelChilIds) {
          label_id = this.labelList[this.labelIdx].son.map(item => { return item.label_id }).filter(item => item).join();
        } else if (this.labelIds && this.labelChilIds) {
          label_id = this.labelChilIds;
        } else if (!this.labelIds && this.labelChilIds) {
          label_id = this.labelChilIds;
        }

        const { data } = await fetchSoundEffect({
          page: this.pager.page,
          limit: this.pager.limit,
          order: this.orderNo,
          // label_id: this.labelIds,
          label_id
        })
        this.pager.total = data.total
        data.list.forEach(item => {
          item.labels = item.label
          item.is_collected = item.is_collect;
        })
        this.list = data.list
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 按最新｜最热排序
     */
    changeSoundOrder() {
      this.getList();
    },
    /**
     * 切换页码
     * @param e.page 页码
     */
    handlePageChange(e) {
      console.log(e)
      this.pager.page = e.page;
      this.getList();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      span {
        padding-left: 15px !important;
      }
    }
  }
}

.sound-effect {
  //margin-top: 70px;
  background: #fafafc;
  min-height: calc(100vh - 70px);
  padding-top: 70px;
  .crumb-wapper{
    margin: -6px 0 20px;
  }
  .order_cont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 125px;

    span {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
    }

    /deep/.el-select {
      width: 108px !important;
      height: 32px !important;
      border: 1px solid #070606;
      border-radius: 5px;

      .el-input--suffix .el-input__inner {
        width: 108px !important;
        height: 32px !important;
        border: none;
        background: none;
      }

      .el-input__icon {
        line-height: 32px !important;
      }
    }
  }

  .tag-container {
    width: 100%;
    background: #fff;
    user-select: none;

    .tag-wrap {
      // max-width: 1200px;
      // margin: 0 auto;
      padding: 32px 0 22px 0;
      box-sizing: border-box;
    }

    .tag-item:nth-last-of-type(1) {
      margin-bottom: 0;
    }

    .tag-item {
      @include center-middle-flex($justify: flex-start);
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
      }

      &-title {
        min-width: 72px;
        //@include font-bold($size: 18px, $color: #333);
        //line-height: 25px;
        //font-family: PingFangSC-Medium, PingFang SC;
        //font-weight: 500;
        margin-right: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      &-content {
        overflow: hidden;

        &-text {
          height: 100%;
          display: inline-block;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
          line-height: 26px;
          cursor: pointer;
          padding: 0 9px;
          box-sizing: border-box;
        }

        &-text:hover {
          background: linear-gradient(90deg, #FF5C22 0%, #E52A0D 100%);
          border-radius: 13px;
          padding: 0 9px;
          box-sizing: border-box;
          color: #FFFFFF;
        }

        &-text.active {
          background: linear-gradient(90deg, #FF5C22 0%, #E52A0D 100%);
          border-radius: 13px;
          padding: 0 9px;
          box-sizing: border-box;
          color: #FFFFFF;
        }
      }
    }

    .tag-sort-box {
      width: 100%;
      display: flex;
      //align-items: center;
      justify-content: space-between;
    }

    .tag_sort {
      flex: 1;
      @include center-middle-flex($justify: flex-start);
      //padding-top: 20px;
      border-top: 1px dashed rgba(0, 0, 0, 0.1);

      &_title {
        margin-right: 12px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .tag_sort_text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-right: 30px;
        cursor: pointer;
      }

      .tag_sort_text.active {
        color: #E52A0D;
      }
    }

    .tag-pagina {
      @include center-middle-flex($justify: flex-start);
      padding-top: 11px;
      border-top: 1px dashed rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;

      i {
        font-style: normal;
        display: inline-block;
      }

      .pagina_l,
      .pagina_r {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .pager {
        margin: 0 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
      }

      .page {
        color: #E52A0D;
      }

      .active {
        border-color: #E52A0D;
        color: #E52A0D;
      }
    }
  }

  &-list {
    padding: 30px 0px 80px 0px;

    &-content {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 18px;
      grid-auto-flow: row;
      margin-bottom: 30px;
    }

    &-pager {
      margin-top: 40px;
    }
  }
}
</style>
